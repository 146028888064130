/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "El desplome en bolsa de Netflix en un 70% desde sus máximos históricos nos pone en la pista de un problema en su modelo de suscripción."), "\n", React.createElement(_components.p, null, "Los modelos de suscripción de acceso a propiedad intelectual adolecen de unos grandes efectos red. Esto se debe a que consumir contenidos es una actividad social, todos queremos comentar nuestra serie preferida con nuestros amigos y que ellos estén en otra plataforma crea muros entre nosotros."), "\n", React.createElement(_components.p, null, "La lección aprendida en las últimas décadas sobre estos efectos red es que favorecen la concentración de empresas creando oligopolios, con unas plataformas que en un principio tienen un carácter generalista y posteriormente se especializan en algún tipo de contenido. Esto es bastante claro en el caso de ", React.createElement(_components.a, {
    href: "https://litox.entramado.net/2022/04/10/el-metaverso-es-una-maniobra-de-marketing/"
  }, "Facebook"), ", que terminó siendo abandonado en favor de Instagram o Twitter, enfocadas en imágenes o texto respectivamente. Estas plataformas están encasilladas en su tipo de contenido y no es fácil que salgan de él, prueba de esto es como Instagram no ha conseguido absorber el formato de TikTok pese a sus esfuerzos."), "\n", React.createElement(_components.p, null, "Tomando este ejemplo, podemos concluir que el mundo del entretenimiento se encuentra en la fase de transición entre plataformas generalistas y las especializadas. Si analizamos el mercado del streaming, la única plataforma un poco más especializada es Disney, basada en franquicias y contenido infantil."), "\n", React.createElement(_components.p, null, "El futuro del entretenimiento va camino a la concentración y la especialización, a muchas de estas plataformas les tocará especializarse o ser absorbidas."), "\n", React.createElement(_components.p, null, "Imagen de ", React.createElement(_components.a, {
    href: "https://unsplash.com/@fran_"
  }, "Frac Jacquier"), " en ", React.createElement(_components.a, {
    href: "https://unsplash.com/"
  }, "Unsplash"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
